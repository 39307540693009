import { createContext } from 'react';
import { MessageData } from './WebsocketProvider';

export interface WebsocketContextProps {
  data: MessageData | null;
}

const WebsocketContext = createContext<WebsocketContextProps>({
  data: null,
});

export default WebsocketContext;
