const ACTIVATE_USER_ACCOUNT = 'ACTIVATE_USER_ACCOUNT';
const RESET_PASSWORD = 'RESET_PASSWORD';
const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
const SHARE_SEARCH = 'SHARE_SEARCH';
const ATTACH_TO_FOLDER = 'ATTACH_TO_FOLDER';
const APPEND_SUBRESOURCES = 'APPEND_SUBRESOURCES';
const REMOVE_SUBRESOURCES = 'REMOVE_SUBRESOURCES';
const UPDATE_DOCUMENT_NOTES = 'UPDATE_DOCUMENT_NOTES';
const SEND_TO_SAGE = 'SEND_TO_SAGE';

const services = {
  RESET_PASSWORD,
  ACTIVATE_USER_ACCOUNT,
  MARK_ALL_AS_READ,
  SHARE_SEARCH,
  ATTACH_TO_FOLDER,
  APPEND_SUBRESOURCES,
  REMOVE_SUBRESOURCES,
  UPDATE_DOCUMENT_NOTES,
  SEND_TO_SAGE,
};

export default services;
