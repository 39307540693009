import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Folder, OperationalData } from 'types';

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<Folder>, Empty>(
    'folder/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = (payload: Folder, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

interface DetailPayload {
  id: number | string;
}

const detail = ({ id }: DetailPayload, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/${id}`,
    {
      method: 'GET',
    },
    token,
  );

const update = (payload: Folder, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/${payload.id}/`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

const remove = ({ id }: DetailPayload, token: string) =>
  request<Folder, Empty, Empty>(
    `folder/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

export interface AppendDocumentsPayload {
  id: number;
  documents: OperationalData[];
}

const appendDocuments = (
  { id, documents }: AppendDocumentsPayload,
  token: string,
) =>
  request<Partial<Folder>, Empty, Empty>(
    `folder/${id}/documents/`,
    {
      payload: { documents },
      method: 'POST',
    },
    token,
  );

interface RemoveSubresourcePayload {
  itemId: number;
  resource: string;
  subItemId: number;
}

const removeSubresource = (
  { itemId, resource, subItemId }: RemoveSubresourcePayload,
  token: string,
) => {
  return request<Folder, Empty, Empty>(
    `folder/${itemId}/documents/${subItemId}/`,
    {
      method: 'DELETE',
    },
    token,
  );
};

interface FolderDocumentQuery extends PageQuery {
  id: string;
}

const folderDocumentList = (query: FolderDocumentQuery, token: string) =>
  request<FolderDocumentQuery, PageResponse<Folder>, Empty>(
    `folder/${query.id}/documents`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const sendToSage = (payload: Folder, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/${payload.id}/send_to_sage/`,
    {
      method: 'POST',
    },
    token,
  );

const foldersServices = {
  list,
  create,
  detail,
  update,
  remove,
  appendDocuments,
  removeSubresource,
  folderDocumentList,
  sendToSage,
};

export default foldersServices;
