import { useSelector } from 'react-redux';
import { ReducerState } from 'store/reducers';

const useGetIdentity = () => {
  const accessToken = useSelector(
    (state: ReducerState & { auth: { access: string } }) => state.auth.access,
  );

  return { accessToken };
};

export default useGetIdentity;
