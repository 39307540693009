import { ReactNode } from 'react';
import { WebsocketProvider } from 'contexts';
import config from 'config';
import useGetIdentity from 'hooks/useGetIdentity';

export interface StoreToContextProps {
  children: ReactNode;
}

const ChannelContext = ({ children }: StoreToContextProps) => {
  const { accessToken: token } = useGetIdentity();

  return (
    <WebsocketProvider
      maxReconnectTries={config.maxReconnectTries}
      url={config.baseWebsocketUrl}
      initialQueryParams={{ token }}
    >
      {children}
    </WebsocketProvider>
  );
};

export default ChannelContext;
