import { createRoutine } from './utils';
import services from 'store/services';
import { OperationalData } from 'types';
import { show } from 'redux-modal';
import { modals } from 'contstants';
import { Dispatch } from 'redux';

export const attachToFolderAction = createRoutine('ATTACH_TO_FOLDER');
export const removeSubresourceActions = createRoutine('DELETE_SUBRESOURCE');
export const sendToSageAction = createRoutine('SEND_TO_SAGE');

export interface RemovePayload {
  itemId: string;
  subItem: OperationalData;
  meta?: Record<string, unknown>;
}

const attachToFolder = (payload: Record<string, any>) =>
  attachToFolderAction({
    ...payload,
    meta: {
      ...(payload.meta && payload.meta),
      service: services.ATTACH_TO_FOLDER,
    },
  });

const removeSubresource = (payload: RemovePayload, dispatch: Dispatch) =>
  show(modals.CONFIRM_DIALOG, {
    title: `Are you sure you want to delete document ${payload.subItem.document_name} from the folder?`,
    confirm: () =>
      dispatch(
        removeSubresourceActions({
          itemId: payload.itemId,
          subItemId: payload.subItem.id,
          meta: {
            service: services.REMOVE_SUBRESOURCES,
            ...(payload.meta && payload.meta),
          },
        }),
      ),
  });

const sendToSage = (payload: Record<string, any>) => {
  return sendToSageAction({
    ...payload,
    meta: {
      service: services.SEND_TO_SAGE,
      ...(payload.meta && payload.meta),
    },
  });
};

const folderActions = {
  attachToFolder,
  removeSubresource,
  sendToSage,
};

export default folderActions;
