import pick from 'lodash/pick';
import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Notification } from 'types';

const list = (query: PageQuery, token: string) =>
  request<Empty, PageResponse<Notification>, Empty>(
    `notifications`,
    {
      method: 'GET',
      query,
    },
    token,
  );

const update = (
  payload: Notification,
  token: string,
  partial: boolean = false,
) =>
  request<Partial<Notification>, Notification, Empty>(
    `notification/${payload.id}/`,
    {
      method: partial ? 'PATCH' : 'PUT',
      payload: {
        ...pick(payload, 'id'),
        unread: false,
      },
    },
    token,
  );

const markAllAsRead = (data: any, token: string) => {
  return request(
    `notifications/mark-all-as-read/`,
    {
      method: 'POST',
    },
    token,
  );
};

const unreadNotifications = (query: PageQuery, token: string) =>
  request<Empty, PageResponse<Notification>, Empty>(
    'notifications/unread/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const notificationsServices = {
  list,
  update,
  markAllAsRead,
  unreadNotifications,
};

export default notificationsServices;
