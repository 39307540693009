import { useContext, useEffect } from 'react';
import WebsocketContext from './WebsocketContext';
import { TypeMessage } from './WebsocketProvider';

export type EventHandlerWithArg<T> = (data: T) => void;
export type EventHandler = () => void;

const useWebsocketEventCallback = <T extends unknown>(
  eventCallback: EventHandlerWithArg<T> | EventHandler,
  eventType: TypeMessage,
) => {
  const { data } = useContext(WebsocketContext);

  useEffect(() => {
    if (data && data.type.toLowerCase() === eventType.toLowerCase()) {
      let value: T;
      try {
        value = JSON.parse(data.data as string) as T;
      } catch (error) {
        value = data.data as T;
      }

      eventCallback(value);
    }
  }, [data, eventType, eventCallback]);
};

export default useWebsocketEventCallback;
