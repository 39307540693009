import { FunctionComponent, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'flexmonster/flexmonster.css';
import moment from 'moment';

import { Provider } from 'react-redux';
import { ConnectedRouter, ConnectedRouterProps } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from 'react-use-intercom';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';

import createStore from 'store';
import config from 'config';
import { rollbarConfig } from 'utils';
import {
  PopoverProvider,
  DrawerProvider,
  DebugControllerProvider,
  ChannelContext,
} from 'contexts';
import Root from 'containers';
import Modals from 'components/modals';
import ThemeProvider from 'components/Theme';
import Notification from 'layouts/Notification';
import { LoggerProvider } from 'contexts/Loggers';

import * as serviceWorker from './serviceWorker';
import './index.css';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  invalidDate: 'N/A',
});

const history = createBrowserHistory();
const { store, persistor } = createStore(history);

export interface ConnectedRouterComponentProps extends ConnectedRouterProps {
  children: ReactNode;
}

const ConnectedRouterComponent =
  ConnectedRouter as unknown as FunctionComponent<ConnectedRouterComponentProps>;

ReactDOM.render(
  <LoggerProvider config={rollbarConfig}>
    <Provider store={store}>
      <ThemeProvider>
        <CssBaseline />
        <PersistGate loading={null} persistor={persistor}>
          <ChannelContext>
            <ConnectedRouterComponent history={history}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <IntercomProvider appId={config.intercomAppId}>
                  <PopoverProvider>
                    <DrawerProvider>
                      <DebugControllerProvider>
                        <Root />
                        <Modals />
                        <Notification />
                      </DebugControllerProvider>
                    </DrawerProvider>
                  </PopoverProvider>
                </IntercomProvider>
              </LocalizationProvider>
            </ConnectedRouterComponent>
          </ChannelContext>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </LoggerProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
