import nodeServices from 'services/nodes';
import { crudActions } from 'store/actions';
import { ResponseData } from './request';
import authService from './auth';
import searchService from './search';
import savedSearchService from './savedSearch';
import foldersService from './folders';
import usersService from './users';
import sanctionsServices from './sanctions';
import organisationsService from './organisations';
import groupsService from './groups';
import dataSourcesService from './dataSources';
import documentTypesService from './documentTypes';
import burdenClassesService from './burdenClasses';
import tagsService from './tags';
import themesService from './themes';
import topicsServices from 'services/topics';
import countriesService from './countries';
import recipientTypeServices from './recipientType';
import regulatorsService from './regulators';
import licensesService from './licenses';
import industryServices from './industry';
import governmentDepartmentsService from './governmentDepartments';
import sourceGovernmentDepartmentsServices from './sourceGovermentDepartment';
import geographicalExtentsService from './geographicalExtents';
import notificationsServices from './noifications';
import breachPrimaryServices from './breachPrimary';
import breachesSupportingServices from './breachesSupporting';
import breachesSecondaryServices from './breachesSecondary';
import breachesObservedServices from './breachesObserved';
import rulesBreachesServices from './rulesBreaches';
import cfServices from 'services/cf';
import simfServices from 'services/simf';
import smfServices from 'services/smf';
import taskServices from 'services/tasks';
import documentCommentsServices from './documentComments';

import resources from 'store/resources';
import services from 'store/services';
import workflowServices from 'services/workflows';
import uploadServices from 'services/upload';
import webhookServices from 'services/webhooks';
import relatedDocuments from 'services/relatedDocuments';
import policyMappingServices from 'services/policyMapping';
import regulatoryCalendarServices from 'services/regulatoryCalendar';
import documentBundlesServices from 'services/documentBundles';
import themesAndTagsServices from 'services/themesAndTags';
import pivotTemplateServices from './pivotTemplate';
import nodesEmailServices from 'services/nodesEmail';
import notesServices from 'services/notes';
import organizationLogServices from './organizationLog';
import documentComparisonServices from './documentComparison';
import s3BucketServices from './s3Bucket';
export { default as authService } from './auth';

export type ServiceFn = (...args: any[]) => Promise<ResponseData<any>>;

export const getService = (resource: string, type: string): ServiceFn => {
  switch (resource) {
    case resources.SEARCH:
      return {
        [crudActions.retrieve.TRIGGER]: searchService.detail,
        [crudActions.list.TRIGGER]: searchService.list,
        [crudActions.update.TRIGGER]: searchService.update,
        [crudActions.remove.TRIGGER]: searchService.remove,
      }[type];
    case resources.ORGANISATIONS:
      return {
        [crudActions.retrieve.TRIGGER]: organisationsService.detail,
        [crudActions.list.TRIGGER]: organisationsService.list,
        [crudActions.remove.TRIGGER]: organisationsService.remove,
        [crudActions.update.TRIGGER]: organisationsService.update,
        [crudActions.create.TRIGGER]: organisationsService.create,
      }[type];
    case resources.INDUSTRY:
      return {
        [crudActions.list.TRIGGER]: industryServices.list,
      }[type];
    case resources.COUNTRIES:
      return {
        [crudActions.list.TRIGGER]: countriesService.list,
      }[type];
    case resources.RECIPIENT_TYPES:
      return {
        [crudActions.list.TRIGGER]: recipientTypeServices.list,
      }[type];
    case resources.GROUPS:
      return {
        [crudActions.retrieve.TRIGGER]: groupsService.detail,
        [crudActions.list.TRIGGER]: groupsService.list,
        [crudActions.remove.TRIGGER]: groupsService.remove,
        [crudActions.update.TRIGGER]: groupsService.update,
        [crudActions.create.TRIGGER]: groupsService.create,
      }[type];
    case resources.USERS:
      return {
        [crudActions.retrieve.TRIGGER]: usersService.detail,
        [crudActions.list.TRIGGER]: usersService.list,
        [crudActions.remove.TRIGGER]: usersService.remove,
        [crudActions.update.TRIGGER]: usersService.update,
        [crudActions.create.TRIGGER]: usersService.create,
      }[type];
    case resources.RULES_BREACHES:
      return {
        [crudActions.list.TRIGGER]: rulesBreachesServices.list,
        [crudActions.retrieve.TRIGGER]: rulesBreachesServices.detail,
        [crudActions.update.TRIGGER]: rulesBreachesServices.update,
      }[type];
    case resources.WORKFLOW:
      return {
        [crudActions.list.TRIGGER]: workflowServices.list,
        [crudActions.create.TRIGGER]: workflowServices.create,
        [crudActions.retrieve.TRIGGER]: workflowServices.detail,
        [crudActions.update.TRIGGER]: workflowServices.update,
        [crudActions.remove.TRIGGER]: workflowServices.remove,
      }[type];
    case resources.WEBHOOKS:
      return {
        [crudActions.list.TRIGGER]: webhookServices.list,
        [crudActions.create.TRIGGER]: webhookServices.create,
        [crudActions.retrieve.TRIGGER]: webhookServices.detail,
        [crudActions.update.TRIGGER]: webhookServices.update,
        [crudActions.remove.TRIGGER]: webhookServices.remove,
      }[type];
    case resources.TASKS:
      return {
        [crudActions.list.TRIGGER]: taskServices.list,
        [crudActions.create.TRIGGER]: taskServices.create,
        [crudActions.retrieve.TRIGGER]: taskServices.detail,
        [crudActions.update.TRIGGER]: taskServices.update,
      }[type];
    case resources.SANCTIONS:
      return {
        [crudActions.list.TRIGGER]: sanctionsServices.list,
      }[type];
    case resources.LICENSES:
      return {
        [crudActions.list.TRIGGER]: licensesService.list,
      }[type];
    case resources.DATA_SOURCES:
      return {
        [crudActions.list.TRIGGER]: dataSourcesService.list,
      }[type];
    case resources.DOCUMENT_TYPE:
      return {
        [crudActions.list.TRIGGER]: documentTypesService.list,
      }[type];
    case resources.BURDEN_CLASSES:
      return {
        [crudActions.list.TRIGGER]: burdenClassesService.list,
      }[type];
    case resources.TAGS:
      return {
        [crudActions.list.TRIGGER]: tagsService.list,
      }[type];
    case resources.TOPICS:
      return {
        [crudActions.list.TRIGGER]: topicsServices.list,
      }[type];
    case resources.BREACHES_PRIMARY:
      return {
        [crudActions.list.TRIGGER]: breachPrimaryServices.list,
      }[type];
    case resources.BREACHES_SECONDARY:
      return {
        [crudActions.list.TRIGGER]: breachesSecondaryServices.list,
      }[type];
    case resources.BREACHES_SUPPORTING:
      return {
        [crudActions.list.TRIGGER]: breachesSupportingServices.list,
      }[type];
    case resources.BREACHES_OBSERVED:
      return {
        [crudActions.list.TRIGGER]: breachesObservedServices.list,
      }[type];
    case resources.THEMES:
      return {
        [crudActions.list.TRIGGER]: themesService.list,
      }[type];
    case resources.REGULATORS:
      return {
        [crudActions.list.TRIGGER]: regulatorsService.list,
      }[type];
    case resources.GOVERNMENT_DEPARTMENTS:
      return {
        [crudActions.list.TRIGGER]: governmentDepartmentsService.list,
      }[type];
    case resources.SOURCE_GOVERNMENT_DEPARTMENTS:
      return {
        [crudActions.list.TRIGGER]: sourceGovernmentDepartmentsServices.list,
      }[type];
    case resources.GEOGRAPHICAL_EXTENTS:
      return {
        [crudActions.list.TRIGGER]: geographicalExtentsService.list,
      }[type];
    case resources.SIMF:
      return {
        [crudActions.list.TRIGGER]: simfServices.list,
      }[type];
    case resources.SMF:
      return {
        [crudActions.list.TRIGGER]: smfServices.list,
      }[type];
    case resources.CF:
      return {
        [crudActions.list.TRIGGER]: cfServices.list,
      }[type];
    case resources.SAVED_SEARCHES:
      return {
        [crudActions.retrieve.TRIGGER]: savedSearchService.detail,
        [crudActions.list.TRIGGER]: savedSearchService.list,
        [crudActions.remove.TRIGGER]: savedSearchService.remove,
        [crudActions.update.TRIGGER]: savedSearchService.update,
        [crudActions.create.TRIGGER]: savedSearchService.create,
      }[type];
    case resources.FOLDERS:
      return {
        [crudActions.retrieve.TRIGGER]: foldersService.detail,
        [crudActions.list.TRIGGER]: foldersService.list,
        [crudActions.remove.TRIGGER]: foldersService.remove,
        [crudActions.update.TRIGGER]: foldersService.update,
        [crudActions.create.TRIGGER]: foldersService.create,
      }[type];
    case resources.FOLDER_DOCUMENTS:
      return {
        [crudActions.list.TRIGGER]: foldersService.folderDocumentList,
      }[type];
    case resources.DOCUMENT_BUNDLE:
      return {
        [crudActions.retrieve.TRIGGER]: documentBundlesServices.detail,
        [crudActions.list.TRIGGER]: documentBundlesServices.list,
        [crudActions.remove.TRIGGER]: documentBundlesServices.remove,
        [crudActions.update.TRIGGER]: documentBundlesServices.update,
        [crudActions.create.TRIGGER]: documentBundlesServices.create,
      }[type];
    case resources.NODES:
      return {
        [crudActions.list.TRIGGER]: nodeServices.list,
        [crudActions.create.TRIGGER]: nodeServices.create,
      }[type];
    case resources.UPLOAD:
      return {
        [crudActions.create.TRIGGER]: uploadServices.create,
        [crudActions.list.TRIGGER]: uploadServices.list,
      }[type];
    case services.ACTIVATE_USER_ACCOUNT:
      return authService.activate;
    case services.RESET_PASSWORD:
      return authService.passwordReset;
    case services.MARK_ALL_AS_READ:
      return notificationsServices.markAllAsRead;
    case services.SHARE_SEARCH:
      return savedSearchService.share;
    case services.ATTACH_TO_FOLDER:
      return foldersService.appendDocuments;
    case services.SEND_TO_SAGE:
      return foldersService.sendToSage;
    case services.REMOVE_SUBRESOURCES:
      return foldersService.removeSubresource;
    case services.UPDATE_DOCUMENT_NOTES:
      return notesServices.updateDocumentNotes;
    case resources.NOTIFICATIONS:
      return {
        [crudActions.list.TRIGGER]: notificationsServices.list,
        [crudActions.update.TRIGGER]: notificationsServices.update,
      }[type];
    case resources.NOTIFICATIONS_UNREAD:
      return {
        [crudActions.list.TRIGGER]: notificationsServices.unreadNotifications,
      }[type];
    case resources.RELATED_DOCUMENTS:
      return {
        [crudActions.list.TRIGGER]: relatedDocuments.list,
        [crudActions.update.TRIGGER]: relatedDocuments.update,
      }[type];
    case resources.RELATED_SEARCH_DETAIL:
      return {
        [crudActions.retrieve.TRIGGER]: searchService.detail,
      }[type];
    case resources.POLICY_MAPPING:
      return {
        [crudActions.list.TRIGGER]: policyMappingServices.list,
      }[type];
    case resources.REGULATORY_CALENDAR:
      return {
        [crudActions.list.TRIGGER]: regulatoryCalendarServices.list,
      }[type];
    case resources.THEMES_AND_TAGS:
      return {
        [crudActions.list.TRIGGER]: themesAndTagsServices.list,
      }[type];
    case resources.PIVOT_TEMPLATES:
      return {
        [crudActions.create.TRIGGER]: pivotTemplateServices.create,
        [crudActions.list.TRIGGER]: pivotTemplateServices.list,
        [crudActions.retrieve.TRIGGER]: pivotTemplateServices.detail,
      }[type];
    case resources.DOCUMENT_COMMENTS:
      return {
        [crudActions.list.TRIGGER]: documentCommentsServices.list,
        [crudActions.listRemove.TRIGGER]: documentCommentsServices.remove,
        [crudActions.listUpdate.TRIGGER]: documentCommentsServices.update,
        [crudActions.create.TRIGGER]: documentCommentsServices.create,
      }[type];
    case resources.NODES_EMAIL:
      return {
        [crudActions.create.TRIGGER]: nodesEmailServices.create,
      }[type];
    case resources.NOTES:
      return {
        [crudActions.list.TRIGGER]: notesServices.list,
        [crudActions.listRemove.TRIGGER]: notesServices.remove,
        [crudActions.listUpdate.TRIGGER]: notesServices.update,
        [crudActions.create.TRIGGER]: notesServices.create,
      }[type];
    case resources.ORGANIZATION_LOGS:
      return {
        [crudActions.list.TRIGGER]: organizationLogServices.list,
      }[type];
    case resources.DOCUMENT_COMPARISON:
      return {
        [crudActions.list.TRIGGER]: documentComparisonServices.list,
      }[type];
    case resources.S3BUCKET:
      return {
        [crudActions.list.TRIGGER]: s3BucketServices.list,
      }[type];
  }
  return (...args: any[]) => Promise.reject('Service not found');
};
