import React from 'react';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { Notification, OnSuccessCallback } from 'types';
import { createRoutine } from 'store/actions/utils';
import { crudActions } from 'store/actions/index';
import resources from 'store/resources';
import services from 'store/services';
import { stringifyQuery } from 'utils';

const showNotificationAction = createRoutine('SHOW_NOTIFICATION');
const hideNotification = createRoutine('HIDE_NOTIFICATION');
export const markAllNotificationAction = createRoutine('MARK_ALL_NOTIFICATION');

export type NotificationType =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'undo';

interface NotificationOptions {
  messageArgs?: any;
  undoable?: boolean;
}

export interface NotificationPayload {
  readonly message: React.ReactNode;
  readonly type: NotificationType;
  readonly notificationOptions?: NotificationOptions;
}

export interface ShowNotificationAction {
  readonly type: string;
  readonly payload: NotificationPayload;
}

export const showNotification = (
  // A translatable label or text to display on notification
  message: string | React.ReactNode,
  // The type of the notification
  type: NotificationType = 'info',
  // Specify additional parameters of notification
  notificationOptions?: NotificationOptions,
): ShowNotificationAction =>
  showNotificationAction({
    notificationOptions,
    type,
    message,
  });

const markNotification =
  (dispatch: Dispatch) =>
  (item: Notification, onSuccessCallback?: OnSuccessCallback) =>
    crudActions.update({
      ...item,
      meta: {
        partial: true,
        resource: resources.NOTIFICATIONS,
        onSuccess: {
          notification: {
            level: 'success',
            message: 'Updated.',
          },
          callback: () => {
            if (
              item.actor &&
              item.actor.request &&
              item.actor.request.search_criteria
            ) {
              dispatch(
                push(
                  `/search/?${stringifyQuery({
                    filters: item.actor.request.search_criteria,
                  })}`,
                ),
              );
            }
            onSuccessCallback && onSuccessCallback();
          },
        },
        onFailure: {
          notification: {
            level: 'error',
            message: 'Somethings went wrong.',
          },
        },
      },
    });

const markAllNotifications = (dispatch: Dispatch) => () =>
  markAllNotificationAction({
    meta: {
      service: services.MARK_ALL_AS_READ,
      onSuccess: {
        callback: () => {
          dispatch(
            crudActions.list({
              meta: { resource: resources.NOTIFICATIONS },
            }),
          );
          dispatch(
            crudActions.list({
              meta: { resource: resources.NOTIFICATIONS_UNREAD },
            }),
          );
        },
        notification: {
          level: 'success',
          message: 'Updated.',
        },
      },
      onFailure: {
        notification: {
          level: 'error',
          message:
            'An unexpected error has occurred. Please check your data and try again or contact support.',
        },
      },
    },
  });

export interface HideNotificationAction {
  readonly type: string;
}

const notificationActions = {
  showNotificationAction,
  showNotification,
  hideNotification,
  markAllNotifications,
  markNotification,
};

export default notificationActions;
