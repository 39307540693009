import { ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import qs from 'query-string';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useToggle } from 'hooks';
import NotAvailable from 'components/NotAvailable';
import WebsocketContext from './WebsocketContext';

export const WEBSOCKET_NORMAL_CLOSE_CODE = 1000;
export const TOKEN_REFRESH = 'TOKEN_REFRESH';
export const NOTIFICATION = 'NOTIFICATION';

export type WS_EVENT_TYPE = {
  TOKEN_REFRESH: string;
  NOTIFICATION: string;
};

export type TypeMessage = keyof WS_EVENT_TYPE;

export interface MessageData {
  type: TypeMessage;
  data: unknown;
}

export interface WebsocketProviderProps {
  children: ReactNode;
  initialQueryParams: Record<string, unknown>;
  url: string;
  maxReconnectTries: number;
}

const WebsocketProvider = ({
  children,
  initialQueryParams,
  url,
  maxReconnectTries,
}: WebsocketProviderProps) => {
  const [data, setData] = useState<MessageData | null>(null);
  const { isMarked: openWebsocket, toggle } = useToggle(false);
  const websocket = useRef<WebSocket | null>(null);
  const currentQueryParams = useRef<Record<string, unknown> | null>(null);
  const { isMarked: showNotAvailable, toggle: toggleNotAvailable } =
    useToggle(false);
  const queryParams = useRef<Record<string, unknown>>(initialQueryParams);

  useEffect(() => {
    queryParams.current = initialQueryParams;
  }, [initialQueryParams]);

  const getKeysWithNullValue = useCallback(
    () =>
      Object.keys(queryParams.current).reduce(
        (keysWithNullValue: string[], key: string) => {
          if (!queryParams.current[key]) {
            keysWithNullValue.push(key);
          }

          return keysWithNullValue;
        },
        [],
      ),
    [queryParams],
  );

  let currentTry: number = 1;
  const connect = useCallback(() => {
    const query = qs.stringify(queryParams.current);
    const socket = new WebSocket(`${url}?${query}`);

    socket.onopen = () => {
      currentQueryParams.current = queryParams.current;
      websocket.current = socket;
      toggle(true);
    };

    socket.onclose = () => {
      websocket.current = null;
      currentQueryParams.current = null;
      setData(null);
      toggle(false);
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data) as MessageData;
      setData(message);
    };

    socket.onerror = () => {
      const keysWithNullValue = getKeysWithNullValue();
      if (!isEmpty(queryParams.current) && isEmpty(keysWithNullValue)) {
        if (currentTry + 1 > maxReconnectTries) {
          toggleNotAvailable();
          return;
        }
        currentTry++;
        setTimeout(connect, 5e3);
      }
    };
  }, [
    url,
    toggle,
    currentTry,
    getKeysWithNullValue,
    maxReconnectTries,
    toggleNotAvailable,
  ]);

  const close = useCallback(() => {
    if (websocket.current && openWebsocket) {
      websocket.current.close(WEBSOCKET_NORMAL_CLOSE_CODE);
    }
  }, [websocket, openWebsocket]);

  useEffect(() => {
    const keysWithNullValue = getKeysWithNullValue();
    if (
      !openWebsocket &&
      !websocket.current &&
      !isEmpty(queryParams.current) &&
      isEmpty(keysWithNullValue) &&
      !isEqual(currentQueryParams.current, queryParams.current) &&
      !showNotAvailable
    ) {
      connect();
    }

    if (
      websocket.current &&
      openWebsocket &&
      !isEqual(currentQueryParams.current, queryParams.current)
    ) {
      close();
    }
  }, [
    connect,
    close,
    queryParams,
    currentQueryParams,
    openWebsocket,
    websocket,
    showNotAvailable,
    getKeysWithNullValue,
  ]);

  return (
    <WebsocketContext.Provider value={{ data }}>
      {showNotAvailable ? <NotAvailable /> : children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
