import {
  Fragment,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import pick from 'lodash/pick';
import { NotificationActionEvent } from 'openfin-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIntercom } from 'react-use-intercom';
import { Notification, OnSuccessCallback, User } from 'types';
import config from 'config';
import { openfin } from 'integrations';

import { useRunOnce, useToggle, useUserDetails } from 'hooks';
import { useDrawer, useWebsocketEventCallback } from 'contexts';
import {
  TOKEN_REFRESH,
  NOTIFICATION,
} from 'contexts/Websocket/WebsocketProvider';
import { formatUser } from 'utils';
import resources from 'store/resources';
import { authActions, crudActions, notificationActions } from 'store/actions';
import { generateResourceSelectors } from 'store/selectors/utils';
import { ReducerState } from 'store/reducers';
import { notificationUnreadSelectors } from 'store/selectors';
import Grid from 'components/Grid';
import Sidebar from 'components/Sidebar';
import NotAvailable from 'components/NotAvailable';
import NotificationsPanel from 'components/NotificationsPanel';
import ErrorBoundary from 'components/ErrorBoundary';
import Toolbar from 'components/Toolbar';
import AppBar from 'components/AppBar';
import getNavigationForUser from './getNavigationForUser';

import DrawerGrid from './DrawerGrid';
import MainContainer from 'layouts/AppLayout/MainContainer';
import MenuBtn from 'layouts/AppLayout/MenuBtn';
import Menu from 'layouts/AppLayout/Menu';
import Logo from 'layouts/AppLayout/Logo';

const drawerWidth = 260;
const drawerClosedWidth = 60;

export interface AppLayoutProps {
  children: ReactNode;
  notificationsCount: number;
  markNotification: (
    item: Notification,
    onSuccessCallback: OnSuccessCallback,
  ) => void;
  getNotification: () => void;
  loadingNotifications: boolean;
  backendStatus: boolean;
  loadingBackendStatus: boolean;
  addNotification: (data: Notification) => void;
  showNotification: (content: string) => void;
  refresh: () => void;
}
const AppLayout = memo(
  ({
    children,
    notificationsCount,
    markNotification,
    getNotification,
    loadingNotifications,
    backendStatus,
    loadingBackendStatus,
    addNotification,
    showNotification,
    refresh,
  }: AppLayoutProps) => {
    const { show, close } = useDrawer();
    const {
      email,
      firstName,
      lastName,
      auth: jwtToken,
      hasAccess,
    } = useUserDetails();

    const { isMarked: isCollapsed, toggle } = useToggle(false);
    const { boot, update } = useIntercom();
    const injected = useRef(false);

    const showNotifications = () => {
      show({
        header: 'Notifications',
        content: <NotificationsPanel />,
      });
    };

    useEffect(() => {
      if (
        config.intercomEnabled &&
        config.intercomAppId &&
        jwtToken &&
        jwtToken.payload &&
        !injected.current &&
        email
      ) {
        injected.current = true;
        boot({
          email,
          customAttributes: pick(jwtToken.payload, [
            'is_org_admin',
            'is_group_admin',
            'is_workflow_admin',
            'is_superuser',
          ]),
          userId: `${jwtToken.payload.user_id}-${email}`,
          name: formatUser({
            first_name: firstName,
            last_name: lastName,
            email,
          } as User),
          hideDefaultLauncher: true,
          customLauncherSelector: '#intercom',
        });
      }
    }, [boot, jwtToken, email, firstName, lastName, injected]);

    useEffect(() => {
      if (
        config.intercomEnabled &&
        jwtToken &&
        jwtToken.payload &&
        injected.current &&
        email
      ) {
        update({
          email,
          customAttributes: pick(jwtToken.payload, [
            'is_org_admin',
            'is_group_admin',
            'is_workflow_admin',
            'is_superuser',
          ]),
          userId: `${jwtToken.payload.user_id}-${email}`,
          name: formatUser({
            first_name: firstName,
            last_name: lastName,
            email,
          } as User),
        });
      }
    }, [email, firstName, jwtToken, lastName, update, injected]);

    useEffect(() => {
      if (config.isOpenFinApp) {
        openfin.subscribe((event: NotificationActionEvent) => {
          markNotification(
            event.notification.customData as Notification,
            close,
          );
        });
      }
    }, [close, markNotification]);

    const addAndShowNotification = useCallback(
      (data: Notification) => {
        addNotification(data);
        showNotification(data.verb);
      },
      [addNotification, showNotification],
    );

    useRunOnce(getNotification);

    useWebsocketEventCallback<Notification>(
      addAndShowNotification,
      NOTIFICATION,
    );

    useWebsocketEventCallback<string>(refresh, TOKEN_REFRESH);

    return (
      <Fragment>
        <AppBar position="fixed">
          <Toolbar disableGutters>
            <DrawerGrid
              actualSize
              container
              opened={!isCollapsed}
              alignItems="center"
              justifyContent="space-between"
              openWidth={drawerWidth}
              closedWidth={drawerClosedWidth}
            >
              <Grid item>
                <Logo opposite={isCollapsed} />
              </Grid>
              <Grid item>
                <MenuBtn collapsed={isCollapsed} onClick={toggle} />
              </Grid>
            </DrawerGrid>
            <Menu
              backendStatus={backendStatus}
              loadingBackendStatus={loadingBackendStatus}
              loadingNotifications={loadingNotifications}
              onNotificationClick={showNotifications}
              notificationsCount={notificationsCount}
              firstName={firstName}
              lastName={lastName}
            />
          </Toolbar>
        </AppBar>
        <Sidebar
          openedWidth={drawerWidth}
          closedWidth={drawerClosedWidth}
          isOpen={!isCollapsed}
          navigation={getNavigationForUser(jwtToken, hasAccess)}
        />
        <MainContainer
          collapsed={isCollapsed}
          expandedWidth={drawerWidth}
          collapsedWidth={drawerClosedWidth}
        >
          <ErrorBoundary>
            {loadingBackendStatus && !backendStatus ? (
              <NotAvailable />
            ) : (
              children
            )}
          </ErrorBoundary>
        </MainContainer>
      </Fragment>
    );
  },
);

export default connect(
  (state: ReducerState) => {
    const backendStatuses = generateResourceSelectors(resources.BACKEND_STATUS);
    return {
      notificationsCount: notificationUnreadSelectors.count(state),
      loadingNotifications: notificationUnreadSelectors.isLoading(state),
      backendStatus: backendStatuses
        .results(state)
        .some((status) => status.status !== false),
      loadingBackendStatus: backendStatuses.isLoading(state),
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        markNotification: notificationActions.markNotification(dispatch),
        getNotification: () =>
          crudActions.list({
            meta: { resource: resources.NOTIFICATIONS_UNREAD },
          }),
        addNotification: (data: Notification) =>
          crudActions.listAppend({
            results: [data],
            meta: {
              resource: resources.NOTIFICATIONS_UNREAD,
            },
          }),
        showNotification: (content: string) =>
          notificationActions.showNotification(content, 'info'),
        refresh: authActions.forcedRefresh,
      },
      dispatch,
    ),
)(AppLayout);
