import Immutable from 'immutable';

import { authActions } from 'store/actions';
import { Errors } from 'types';
import { createReducer } from './utils';

export interface AuthState {
  isLoading: boolean;
  isSubmitting: boolean;
  isRefreshing: boolean;
  id: number | null;
  access: string | null;
  refresh: string | null;
  username: string | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  errors: Errors;
}

export type AuthRecord = Immutable.Record<AuthState>;

export const AuthInitialState = Immutable.Record<AuthState>({
  id: null,
  isLoading: false,
  isSubmitting: false,
  access: null,
  refresh: null,
  username: null,
  email: null,
  first_name: null,
  last_name: null,
  isRefreshing: false,
  errors: {},
});

const initialState = AuthInitialState();

const resetState = (state: AuthRecord): AuthRecord =>
  state.merge({
    id: null,
    access: null,
    refresh: null,
    username: null,
    email: null,
    first_name: null,
    last_name: null,
    isSubmitting: true,
    isRefreshing: false,
    errors: {},
  });

const setSubmitting = (state: AuthRecord): AuthRecord =>
  state.merge({
    isSubmitting: true,
    errors: {},
  });

const setLoading = (state: AuthRecord): AuthRecord =>
  state.merge({
    isLoading: true,
    errors: {},
  });
const resetLoading = (state: AuthRecord): AuthRecord =>
  state.merge({
    isLoading: false,
    isSubmitting: false,
    isRefreshing: false,
    errors: {},
  });
const failureHandler = (state: AuthRecord, errors: Errors): AuthRecord => {
  return resetLoading(state).set('errors', errors);
};
const signInUpdate = (state: AuthRecord, { access, refresh }: AuthState) =>
  state.merge({
    access,
    refresh,
    isSubmitting: false,
    isLoading: false,
    isRefreshing: false,
  });
const update = (state: AuthRecord, payload: AuthState) =>
  state.merge({ ...payload, isSubmitting: false, isLoading: false });
const resetToken = (state: AuthRecord) =>
  state.merge({
    access: null,
    refresh: null,
    username: null,
    email: null,
    first_name: null,
    last_name: null,
    errors: {},
    isSubmitting: false,
    isLoading: false,
    isRefreshing: false,
  });

const refresh = (state: AuthRecord) => state.merge({ isRefreshing: true });

export default createReducer<AuthState, AuthRecord>(
  {
    [authActions.signIn.TRIGGER]: resetState,
    [authActions.signIn.FAILURE]: failureHandler,
    [authActions.signIn.SUCCESS]: signInUpdate,
    [authActions.signInWithAuth0.TRIGGER]: resetState,
    [authActions.signInWithAuth0.FAILURE]: failureHandler,
    [authActions.signInWithAuth0.SUCCESS]: signInUpdate,
    [authActions.signUp.TRIGGER]: resetState,
    [authActions.signUp.FAILURE]: failureHandler,
    [authActions.signUp.SUCCESS]: update,
    [authActions.userDetailGet.TRIGGER]: setLoading,
    [authActions.userDetailGet.FAILURE]: failureHandler,
    [authActions.userDetailGet.SUCCESS]: update,
    [authActions.userDetailUpdate.TRIGGER]: setSubmitting,
    [authActions.userDetailUpdate.FAILURE]: failureHandler,
    [authActions.userDetailUpdate.SUCCESS]: update,
    [authActions.resetPassword.FAILURE]: failureHandler,
    [authActions.resetPasswordConfirm.FAILURE]: failureHandler,
    [authActions.userCleanup.TRIGGER]: resetToken,
    [authActions.activateUserAccount.SUCCESS]: update,
    [authActions.activateUserAccount.FAILURE]: failureHandler,
    [authActions.refresh.TRIGGER]: refresh,
  },
  initialState,
);
